/* ======= header-styles H-Theme ======= */

/* ---=== -- Blue Steel Theme -- CSS -- --==-- */
@import "bsColors";
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
html {
	--bgMoveX : 0;
}
/* ### Loader ### */
body::before {
	width: 100%;
	height: 100%;
	display: block;
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	background-color: $ice;
	z-index: 3000;
	transition: all ease 650ms;
}
body::after {
	/* ---
	display: block;
	content: "Loading...";
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 3002;
	color: white;
	font-size: 20px;
	transform: translate(-50%,-50%);
	transition: all ease .5s;
	---*/
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	background-color: #fafafa;
}
::-webkit-scrollbar-thumb {
	background-image:  linear-gradient(182deg, #d5d5d5, #87bcbf);
	border-radius: 50px;
}
body .layout .layout-flexible-content {
	header header.l-header {
		.menu { 
			opacity: 0;
			transition: all ease .5s;
		}
		.middle-header-row.no-container .header-middle-mid.header-mid .header-search-wrapper {
			opacity: 0;
			transition: all ease .3s;
			
			.block.block-menu-menu-products-maincats-searchcap {
				ul.menu-dropdown {
					opacity: 0;
					transition: all ease 1s;
					
					li.search-cap-opener.csscontent-all ul {
						opacity: 0;
						transition: all ease 1s;
					}
				}		
				    
			}
		}
		.header-bottom-row {
			.l-region--header-middle-bottom.header-middle-bottom.header-mid {
				.block.block-system-main-menu.main-menu.block-menu {
					.menu-dropdown ul {
						opacity: 0;
						transition: all ease 1s;
					}
				}
			}
			.header-left-bottom.header-left .header-identity-wrapper {
				.header-site-name-wrapper a.header-site-name-link .header-logo-wrapper img.header-logo {
					opacity: 0;
					transition: all ease .5s;
				}
			}
		}
		
	}
}

body.finishload::before ,body.finishload::after {
	opacity: 0;
}
body.ishide::before ,body.ishide::after {
	display: none;
}
/*
body.finishload.ishide.overlay-categories .layout .layout-flexible-content header.l-header .no-container.region_12 .row .l-col.col-md-12,
body.finishload .layout .layout-flexible-content .flexible-row .no-container.hero-block .row .l-col.col-md-12.l-region--hero-block	{
	z-index: 1;
	transition: 80ms ease;

}
*/
body.finishload.ishide .layout .layout-flexible-content {
	
	header header.l-header {
		.menu { 
			opacity: 1;
		}
		.middle-header-row.no-container .header-middle-mid.header-mid .header-search-wrapper {
			opacity: 1;
			
			.block.block-menu-menu-products-maincats-searchcap {
				ul.menu-dropdown {
					opacity: 1;
					
					li.search-cap-opener.csscontent-all ul {
						opacity: 1;
						
					}
				}		
			}
		}
		.header-bottom-row {
			.l-region--header-middle-bottom.header-middle-bottom.header-mid {
				z-index: 10;
				.block.block-system-main-menu.main-menu.block-menu {
					.menu-dropdown ul {
						opacity: 1;
						background-color: $steelBluegreen;
					}
					ul ul li {
						a {
							color: $drab;
						}
						a:hover, a:active {
							color: $ice;
						}
						a.highlighted {
							color: $ice;
							opacity:.7;
						}
					}
				}
			}
			.header-left-bottom.header-left .header-identity-wrapper {
				.header-site-name-wrapper a.header-site-name-link .header-logo-wrapper img.header-logo {
					opacity: 1;
				}
			}
		}
	}
}

body.logged-in {
	div#admin-bar-wrapper {
		li a,
		li.expandable span.menu {
			letter-spacing: 1.1px;
			font-size: 13px;
			color: $ice;
			font-weight: 500;
		}
		li.expandable span.menu {
			position: relative;
			top: 0px;
			padding-top: 10px;
			margin-top: -1px;
			font: {
				family: $linkFont;
				weight: 100;
				size: 16px;
			}
			letter-spacing: 1.5px;
			cursor: grab;
		}
	}
	.block.block-system-tabs {	
		grid-column: span 3;
		width: 100%;
		
		.block-content {
			margin: 0 16px;
		
			nav.tabs:before {
				background-color: $drab;
				opacity: 0.75;
				transition: 1s;
			}
		}
	}
}

body > .layout--flexible.layout.layout {
    overflow-x: hidden;
	height: auto;
	background-color: $ice;
	background-color: #f0f3f47a;
	transition: 120ms ease;
}

.flexible-row {
	a, a:hover, a:link, a:active, a:visited {
		font: {
			family: $linkFont;
			weight: 500;
		}
		letter-spacing:2px;
	}
}
.breadcrumb a.crumb.crumb-front {
    display: none;
}
form#user-login {
	input#edit-submit.button-primary {
		background-color: $drab;
		border-radius: 100px;
		padding: 0 8vw;
		font: {
			family: $linkFont;
			size: 20px;
		}
		line-height: 1.9;
		color: $ice;
		width: 100%;
		text-align: center;
	}
}
form#user-login {
	input#edit-submit.button-primary:hover {
		background-color: $steelBluegreen;
		color: #FFF;
	}
}
.l-region--top-nav.active {
	background-color: rgba(0, 62, 81, 0.8);
	
	.topbar-mobile {
		background-color: rgba(142, 140, 160, 0.9);
	}
}
.l-header .menu, .l-header .menu li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.l-region--hero-block {
	.block.mega-menu {
		padding-left: 0px;
		padding-right: 0px;
		position: fixed;
		top: -100%;
		left: 0;
		right: 0;
		z-index: 1234;
		opacity: 0;
		background: white;
		transition: 300ms ease-in-out;
		visibility: hidden;
	}
}
/* ---- MOBILE FIRST +++++========= */
/* =++=##  MOBILE & BASE styles ##=++= */

.block-block-call-toll-free-desktop {
	h3.call-toll-free-desktop, 
	h3.call-toll-free-desktop em {
		display: none;
	}
}
.show-desktop {
    display: none;
}

/* ==## MOBILE & BASE ##== */
header.l-header  {
	.top-nav-region {
		position: relative;
		
		.l-region--top-nav {
			display: grid;
			grid-template: {
				areas:	'topBar topBar topBar'
						'primaryNav sysHeader searchFrm';
				rows: 20px 45px;
				columns: 1fr 5fr 1fr 0 0;
			}
			align-items: center;
			background-color: #084456;
			position: fixed;
			top: -73px;
			left: 0;
			width: 100%;
			z-index: 2222;
			transition: all ease 0.5s;
			padding: 0;
			height: 64px;
			min-height:0;
			margin-left: 0;
			margin-right: 0;
			
			.block.block-block-topbar-mobile.mobile-display.mobile-topbar {
				grid-area: topBar;
				width:100%;
				display: block;
				height: 20px;
				overflow: hidden;
				
				.topbar-mobile {
					font-size: 12px;
					text-align: center;
					text-transform: capitalize;
					padding: 0;
					background: $steelBluegreen;
					color: $drab;
					transition: all ease 0.5s;
				}
			}
			.block-system-header {
				grid-area: sysHeader;
				padding: 5px;
				position: relative;
				height: 100%;
				
				.block-content {
					display: block;
					height: 100%;

					.header-identity-wrapper {
						float: none;
						height: 100%;
						
						.header-site-name-wrapper {
							display: block;
							padding:0;
							height: 100%;
							
							a.header-site-name-link {
								max-width: max-content;
								padding: 0;
								margin: 0 auto;
								height: 100%;
								
								.header-logo-wrapper {
									width: 100%;
									max-width: 100%;
									height: 100%;
									margin: 0;
									z-index: 222;
									
									img.header-logo-wrapper {
										display: block;
										position: static;
										max-width: 145px;
										height: auto;
										margin-top: -2px;
										width: auto;
									}
									img.header-logo {
										width: auto;
										max-width: 100%;
										height: 100%;
									}
								}
							}
						}
					}
				}
			}
			.block.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
				grid-area: primaryNav;
				margin-left: 5vw;
				
				h2.block-title {
					display: none;
				}
				.block-content {
					float: right;					
							
					.menu-toggle-button {
					    display: grid;
						grid: {
							template: {
								columns: auto 1fr auto;
								rows: 1fr 2.5px 1fr 2.5px 1fr 2.5px 1fr;
								areas:	'. . .'
										'. topBar .'
										'. . .'
										'. middleBar .'
										'. . .'
										'. bottomBar .'
										'. . .';
							}
							row: {
								gap: 0;
							}
						}
						padding: 2px 7px;
						top: 73px;
						width: 50px;
						height: 35px;
						background-color: #00658569;
						border: 2px solid #ccc;
						border-radius: 4px;
						margin-left: 0;
						margin-bottom: 0;
						text-indent: 0;
						overflow: hidden;
						transition: .4s ease;
						
						span {
							height: 100%;
							width: 100%;
							max-width: 100%;
							clip: unset;
							top: 0;
							left: 0;
							opacity: 1;
							visibility: visible;
							background-color: #6e8ca0;
							overflow: hidden;
							border-radius: 100px;
						}
						span.menu-toggle-button-icon {
							grid-area: topBar;
						}
						span.menu-toggle-button-text {
							grid-area: middleBar;
						}
						span.menu-toggle-assistive-text {
							grid-area: bottomBar;
						}
						span.menu-toggle-button-icon::before,
						span.menu-toggle-button-icon::after {
							content: none;
						}
					}
					.menu-toggle-button.pinned {
					    top: 100px;
					}
					ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed {
						.top-bar-mobile-nav.social-icon-block {
							float: none;
							display: flex;
						}						
						li.expanded.has-children ul a {
							padding-left: 62px;
						}
					}
				}
			}
				
			.block.block-search-form.search-mobile.search-glass.mobile-display {
				grid-area: searchFrm;
				margin-right: 5vw;

				.search-glass-items {
					cursor: pointer;
					position: fixed;
					top: -100%;
					left: 0;
					background: $drab;
					z-index: 1234;
					width: 100%;
					opacity: 0;
					visibility: hidden;
					box-shadow: 0 0 12px -1px $shadow_A;
					transition: 850ms ease, opacity 400ms ease 600ms;
					
					.search-block-form.search-box {
						float: none ;
						width: auto ;
						top: auto ;
						height: auto ;
						
						>div {
							display: flex;
						}
						
						.form-item.form-type-search.form-item-search-block-form {
							float: none;
							width: 100%;
							margin: 16px;
							
							input.form-search {
								top: auto ;
								width: 100%;
								border-radius: 6px;
							}
						}
						.form-actions {
							margin: 16px;
						
							input.form-submit {
								color: $ice;
							    line-height: 2.35;
								background-size: 50%;
								margin: 0;
								border-radius: 6px;
								border: 2px solid #117cbf;
								box-shadow: inset 0px 0px 6px 2px #114a75;
								
								&:active {
									color: #FFF;
									background-color: #006585;
									box-shadow: 
										inset 0px 0px 18px 4px #114a75, 
										inset 0px 0px 4px 1px #009bff, 
										0 0 10px -4px #fff, 
										0 0 24px -4px #0073bd;
									text-shadow: 
										0 0 6px #fff, 
										0 0 20px #fff;
									border-color: $linkBlue;
									transition: 100ms ease, 
										border-color 0s 0s,
										box-shadow 360ms ease 100ms, 
										text-shadow 250ms ease 0s;
								}
							}
						}
					}
				}
				.search-glass-items.open {
					top: 0;
					opacity: 1;
					visibility: visible;
					transition: 550ms ease, opacity 500ms ease 0s;
				}
				.search-icon-toggle {
					width: 50px;
					height: 35px;
					background-color: #00658569;
					border: 2px solid #006585;
					border-radius: 4px;
					text-align: center;
					line-height: 3.6;
					cursor: pointer;
					
					svg {
						g#Search-right {
							circle, line {
								stroke-width: 4px;
							}
						}
					}
					svg:not(:root) {
						overflow: visible;
					}
				}
			}
		}
	}
}
 
/* ==## MOBILE & BASE JS ##== */

.js header.l-header {
	.l-col.col-md-12.l-region--top-nav {
		.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
			ul.menu.menu-dropdown {
				.top-bar-mobile-nav ul {
					display: block;
					
					li {
						border-bottom: none;
						width: 85px;
						float: right;
						
						a {
							padding: 4px;
							font-size: 14px;
						}
					}
				}
			}
		}
			
		.block.block-block-topbar-mobile.mobile-display.mobile-topbar {
			width:100%;
			display: block;
				
			.topbar-mobile {
				font-size: 12px;
				max-height: 28px;
				text-align: center;
				text-transform: capitalize;
				
				p {
					font: {
						size: 13px;
						family: 'Open Sans';
						weight: 800;
						style: oblique;
					} 
					a {
						color: $drab;
						font: {
							size: 13px;
							family: 'Open Sans';
							weight: 800;
							style: oblique;
						}    
						margin: 0;
						letter-spacing: .02px;
					}
				}
			}
		}
		.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
			
			ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed {
				display: block;
				position: absolute;
				visibility: visible;
				opacity: 1;
				top: -120vh;
				background-color: $onyxLte;	
				transition: 400ms ease-in-out;
			}
			ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed.open {
				top: 73px;
				visibility: visible;
				opacity: 1;
				transition: 350ms ease-out;
			} 
			ul.menu.menu-dropdown {
				li {
					border-bottom-color: #000;
					border-bottom-width: 2px;
					a {
						span.sub-arrow {
							position: absolute;
							top: 7px;
							margin: 0;
							left: auto;
							right: 24px;
							width: 24px;
							height: 24px;
							overflow: hidden;
							border: none;
							background: transparent;
							text-align: center;
							text-shadow: none;
						}
						span.sub-arrow:before {
							display: block;
							content: '';
							background: url(../images/arrow-menu.png) no-repeat;
							width: 24px;
							height: 24px;
							margin: 0;
							display: block;
							position: absolute;
							top: 0;
							transition: .3s ease;
						}
					}
					a.highlighted {
						span.sub-arrow:before {
							display: block;
							content: '';
							transform: rotate(90deg);
							-webkit-transform: rotate(90deg);
							transition: .3s ease;
						 } 
					}
				}
				.top-bar-mobile-nav {
					ul {
						position: relative;
						order: 2;
						display: flex;
						justify-content: center;
						padding: 5px 0 !important;
						background: transparent;
						
						li, a.has-submenu.highlighted {
							border-bottom: 1px solid $steelGrayDrk;
						}
						li {
							border-bottom: none;
							
							a {
								padding-left: 20px;
							}
						}
						li:last-child a {
							margin-right: 0;
						}
					}
					.close-product-dialog {
						width: 30px;
						z-index: 111111;
						height: 30px;
						top: 20px;
						left: 10%;
						position: absolute;
						background: url(/themes/hallowell_theme/images/close.png) no-repeat center;
						background-size: 100%;
						cursor: pointer;
						opacity: 0.7;
					}
					.close-product-dialog:before,
					.close-product-dialog:after {
						background: $ice;
					}
				}
				li.expanded.has-children ul li {
					padding-left: 0;
					padding-right: 0;
					
					a {
						color: $steelBluegreen;
					}
				}
			}
		}
	}	
}
.js .menu-dropdown li.has-children > a {
	padding-right: 3.625rem;
}
header.l-header .top-nav-region {
	.l-col.col-md-12.l-region--top-nav.active {
		background-color: #084456eb;
		top: 0;
		transition: .4s ease;
		
		.block.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display .block-content {
			
			label.menu-toggle-button {
				border-color: $steelBluegreen;
				top: 0;
				transition: .5s ease;
			}
		}
	}
}

/* ==## MOBILE & BASE ##== */
.menu-toggle-button,
.menu-toggle-state,
.mobile-display {
	display: block;
}
header header.l-header {
	display: none;
}
.search-block-form.search-box > div {
	display: flex;
}
/* ==## MOBILE ##== */
.header-site-name-link {
	background-color: transparent;
}
.l-header .menu-toggle-button-text {
	color: #bcbbbb;
	opacity: 0;
	visibility: hidden;
	width: 0;
	padding: 0;
	text-indent: 0;
}
.l-header .menu {
	padding: 15px 35px;
	top: 0;
	left: 0;
	z-index: 1234;
	background: #000;
} 
/* ==## MOBILE & BASE ##== */	
/* ### Messages Area ### */
.layout .l-messages {
	padding-top:2px;
}

/* ==## MOBILE & BASE ##== */
.block.block-mini-layouts-header-blustl .no-container.region_12.top-nav-region .l-flexible-row.row {
    height: 64px;
}
.topText {
	margin-top: 2px;
	
	ul {
		margin: auto;
		padding:0;
		
		li {
			margin: auto;
			padding:0;
			list-style: none;
			
			.row.company-desc {
				text-align: center;
				width: 100%;
				position: relative;
				display: inline-block;
				margin: auto;
								
				p {
					@include companyDesc;
					margin: {
						bottom: 0;
						top: 4px;
					}
				}
			}
			h1.field-content.row.company-desc.text-line-1 {
				@include companyDesc;
				padding: {
					top: 5px;
					bottom: 5px;
					left: 15px;
					right: 15px;
				}
				color: #6e8ca0;
				text-shadow: 1px 1px 1px #fff;
			}
		}
	}
}
/* ==## MOBILE & BASE ##== */
.header-left-bottom.header-left .block-system-header .block-content .header-identity-wrapper,
.header-identity-wrapper .header-site-name-wrapper,
.l-header a.header-site-name-link {
	width: auto;
}

.js .menu-dropdown > li:hover {
    background-color: #114a75;
}

body .layout {
	padding-top:0;
	margin-top:0;
}

/* ==## MOBILE & BASE ##== */
/* ### searchcap Products Menu ### */
.block.block-menu-menu-products-maincats-searchcap ul.menu-dropdown {
	a span.sub-arrow {
		border-top-color :#555555 ;
	}
	a.has-submenu {
		display: flex;
		padding: 0 ;
		align-items: center;
		justify-content: center;
		background-image: linear-gradient( to bottom, #ffffff59, #ffffff61 50%, #00000005 50%, #ffffff30 );
		cursor: pointer;
	}	
	
	li.first.last.expanded.has-children.menu-mlid-564.search-cap-opener {
		height: auto ;
		
		a.nolink.has-submenu {
			background-color: $sage;
			color :#555555 ;
			font-weight: bold;
		}
		
		ul.sm-nowrap {
			background-color: #ececec;
			border-radius: 0 0 6px 6px;
			overflow: hidden;
			box-shadow: 4px 4px 30px -2px #00000045;
			border: 2px solid #fff;
			
			li a {
				border: {
					bottom: 1px solid #c1d0d5;
					top: 1px solid #e2f5fb;
				}
				padding-top: 12px;
				padding-bottom: 12px;
				font-size: 15px;
			}
		}
	}
}


/* ==## MOBILE & BASE ##== */
.middle-header-row .l-flexible-row {
	align-items: center;
}

/* ++++ ## Breadcrumbs - crumbles mobileFirst ##### ++*/
.block.block-easy-breadcrumb-easy-breadcrumb {
	font: {
		size: 10px;
		family: $smallFont;
	}
	/* background-color: #ffffffa6; */
	
	.container {
		max-width: 100%;
		padding: 0;
		width: auto;

		.logged-in .breadcrumb {
			padding: 55px 20px 0;
		}
		.breadcrumb {
			background: transparent;
			display: flex;
			flex-wrap: wrap;
			margin: 5px 20px 12px 20px;
			padding: 0;
			position: relative;
			z-index: 2;
		  
			.item:nth-child(2) {
				position: relative;
				z-index: 222;
			}	
			.item:last-child {
				width: 100%;
				pointer-events: none;
				
				.crumb-title {
					margin: 2px 0 0;
					text-align: left;
					font: {
						size: 22px;
						family: $headingFont;
						weight: 500;
					}
					color: $drab;
					text-transform: uppercase;
					line-height: 1.1;
					opacity: 1;
				}
			} 
			span {
				.crumb  {
					font: {
						size: 10px;
						family: Open Sans;
					}
					letter-spacing: normal;
					color: $steelBluegreen;
					padding-right: 4px;
					position: relative;
					display: flex;
					align-items: center;
					opacity: .8;
					text: {
						decoration: none;
						shadow: 1px 1px 1px #fff;
					}
				}
			}
			a::after {
				display: block;
				content: "/";
				padding-left: 4px;
			}
		}
		a.crumb {
			color: #6e8ca0;
			text-decoration: none;
			padding: {
				top: 8px;
				bottom: 4px;
			}
			span {    
				background-color: transparent;
				border-radius: 7px;
				padding: 1px 5px;
				transition: .5s ease;
			}
		}
		a.crumb:after {
			content: '/';
			color: #003e51;
			text-decoration: none;
		}
		a.crumb:hover,
		a.crumb:active {
			color: $linkBlue;
			text-decoration: none;
			
			span {    
				background-color: #fff;
				border-radius: 7px;
			}
		}
	}
}
.view.view-child-category.view-id-child_category {
	.item-list {
		.views-field.views-field-name {
			.cat-title-wrap.has-parent {
				h3.term-title {
					display: none;
				}
			}
		}
	}
}
/************ ==product page TOP cat ref 'more from' -----------*****/
.product-type-more p span.noSubCats {
    display: inline-block;
}


/* ==## END MOBILE & BASE END ##== */

/* ++==## == BEGIN - MOBILE ONLY == ##==++ */
@media (max-width: 767px) {
/* only mobile */
	header.l-header {
		.top-nav-region {
			.l-region--top-nav {
				.block.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
					.block-content {
						ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed {
							min-width: 400px;
							max-width: 100%;
							min-height: 100vh;
							transition: .4s ease;
							position: absolute;
							
							li {
								a {
									padding: 10px 8vw;
									color: $sage;
									border-bottom-color: $steelBluegreen;
								}
								a:hover, a:active, a:focus {
									color: $drab;
									background-color:$sage;
								}
							}
							li.first.expanded.has-children.menu-mlid-512 > ul {
								border: 2px solid;
								border-radius: 0 0 8px 8px;
								margin-bottom: 34px;
								overflow: hidden;
							}
							ul.menu-top-only.menu.menu-style-processed {
								justify-content: flex-end;
								
								li {
									a {
										padding: 10px 9px;
									}
								}
								
							}
						}
						.menu-dropdown {
							ul {
								width: auto ;
								max-width: 100% ;
								min-width: 100% ;
							}
							
						}
						
						.menu-dropdown > li {
							width: 100%;
						}
						ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed {
							> li.green-item,
							> li.pricebook-item,
							> li.ecustomer-item,
							> li.lte-item {
								border-bottom: none;
								padding-left: 20px;
								padding-top: 3px;
								
								a {
									padding: 10px 64px;
								}
								a:hover, a:active, a:focus {
									background-color:#afc7d68a;
									color: $ice;
								}
							}
						}
						.menu-dropdown > li.green-item {
							margin-top: 30px;
						}
						.menu-dropdown > li.green-item:before {
							background: url(../images/green-icon.png) no-repeat;
						}
						.menu-dropdown > li.pricebook-item:before {
							background: url(../images/price-book-icon.png) no-repeat;
						}
						.menu-dropdown > li.ecustomer-item:before {
							background: url(../images/e-customer-icon.png) no-repeat;
						}
						.menu-dropdown > li.lte-item:before {
							background: url(../images/lead-time-icon.png) no-repeat;
						}
						.menu-dropdown > li.green-item:before,
						.menu-dropdown > li.pricebook-item:before,
						.menu-dropdown > li.ecustomer-item:before,
						.menu-dropdown > li.lte-item:before {
							content: '';
							width: 30px;
							height: 30px;
							display: inline-block;
							position: absolute;
							left: 21px;
							top: 58%;
							transform: translate(0, -50%);
						}
						.menu-dropdown > li:hover {
							background-color: transparent;
						}
					}
				}
			}
			.l-region--top-nav.active  {
				.block.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
					.block-content {
						ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed.open {
							top:0;
						}
					}
				}
			}
		}
	}
	body.logged-in {
		#admin-bar {
			z-index: 2250;
		}
		header.l-header .top-nav-region .l-col.col-md-12.l-region--top-nav {
			transition: 550ms ease-out;
		}
		header.l-header .top-nav-region .l-col.col-md-12.l-region--top-nav.atTop {
			padding-top: 33px;
			background-color: $drab;
			transition: 350ms ease;
		}
	}
						
} /* ==## END MOBILE ONLY END ##== */


/*####### -- +++++ media 660 +++++ ---=== ###*/
@media(min-width:660px) {
	.block-easy-breadcrumb-easy-breadcrumb .container .breadcrumb .item:last-child .crumb-title {
		margin: 0;
		text-align: left;
		font-size: 32px;
		font-weight: 800;
	}
	form#user-login {
		input#edit-submit.button-primary {
			background-color: $drab;
			border-radius: 100px;
			padding: 0 8vw;
			font: {
				family: $linkFont;
				size: 21px;
			}
			line-height: 1.8;
			color: $ice;
			width:auto;
		}
	}
	form#user-login {
		input#edit-submit.button-primary:hover {
			background-color: $steelBluegreen;
			color: #FFF;
		}
	}
	.topText ul li h1.field-content.row.company-desc.text-line-1 {
		padding-top: 10px;
		padding-bottom: 10px;
		font-weight: 200;
		font-size: 16px;
	}
}/*####### END ----- media 660 ----- ---=== ###*/
/* ==## media 768 iPad1 ##== */
/* only desktop & up */
@media (min-width: 768px) {
	
/* #### BEGIN 768 - ipad1 desktop #### */
	body.finishload.ishide .layout .layout-flexible-content {
		header.l-header .no-container.region_12 .row .l-col.col-md-12 {
			z-index: 2;
			transition: 50ms ease;
		}
	}
	body .layout .layout-flexible-content {
		.block-menu-menu-mobile-primary-nav .block-title,
		.mobile-display, 
		.js header.l-header .l-region--top-nav .block.block-block-topbar-mobile.mobile-display.mobile-topbar,
		.l-header .no-container.region_12.top-nav-region .row .l-col.col-md-12.l-region--top-nav {
			display:none;
		}
		.l-header {
			.menu {
				padding: 0;
				display: flex;
				background: transparent;
			}
			.region_12 {
				.l-flexible-row.row {
					margin-right: 0px;
					margin-left: 0px;
				}
				.row .l-col.col-md-12 {
					padding-right: 0px;
					padding-left: 0px;
					padding-bottom: 0;
				}
			}
		}
		/* ==## media 768 iPad1 ##== */
		header.l-header {
			margin: 0;
		}
		header header.l-header {
			display:block;
			
			/* ==## media 768 iPad1 ##== */
			.header-top-bar {
				background-color: $ice;
				height: 24px;
				
				.block.block-block-covid-message.covid-message {
					padding-top: 0px;
					font-weight: lighter;
					color: $drab;
					font-size: 11px;
					margin-left: -50%;
					line-height: 25px;

					strong {
						font-size: 13px;
						font-weight: bold;
						font-family: "Open Sans";
						color: #444444;
					}
					a {
						font-weight: normal;
						color: $drab;
						text-decoration: underline;
						margin-left: 20px;
						letter-spacing: 2px;
					}
					a.blue-link:hover {
						color: blue;
					}
				}
				.social-icon-block {
					float: right;
					
					.block-content ul {
						padding: 0;
						position: unset;
						display: flex;
						justify-content: center;
						/* padding: unset; */
						top: unset;
						left: unset;
						width: unset;
						height: unset;
						z-index: unset;
						background: transparent;
						margin-top: 3px;
						
						li {
							a {
								font: {
									size: 11px;
									weight: normal;
									family: "Open Sans";
								}
								letter-spacing: normal;
								padding: 0 0 0 20px;
								margin-right: 20px;
								background: {
									position: top left;
									repeat: no-repeat;
									size: contain;
								}
							}
							a.hall-fb {
								background-image: url(../images/icon/fb-link.webp);
							}
							a.hall-twitter {
								background-image: url(../images/icon/xt-link.webp);
							}
						}
					}
				}
				.header-left {
					padding-left: 30px;
				}
			}
			/* ==## media 768 iPad1 ##== */
			.middle-header-row.no-container {
				background-color: $drab;
				height: 60px;

				.header-left-mid.header-left {
					.block-block-header-left {
						span.quality-tag {
							min-width: 230px;
							text-align: center;
							padding-left: 0px;
							width: 280px;
							display: block;
							position: relative;
							top: 3px;
							left: -33px;
							color: $ice;
							text-transform: capitalize;
							
							strong {
								font: {
									size: 12px;
									family: 'Chivo';
									weight: 600;
								}
								color: $ice;
							}
						}
					}
				}
				/* ==## media 768 iPad1 ##== */			
				.header-middle-mid.header-mid {
					.header-search-wrapper {
						top: 1px;
						position: relative;
						
						input.form-submit, input.form-search, li.search-cap-opener, a.nolink.has-submenu {
							height: 25px;
						}
						.block.block-search-form.search-glass-desktop form.search-box {
				
			}
						.search-block-form.search-box {
								
							/* Chrome/Opera/Safari */
							input.form-search::-webkit-input-placeholder { 
								@include fontReset;
							}
							/* Firefox 19+ */
							input.form-search::-moz-placeholder { 
								@include fontReset;
							} 
							/* IE 10+ */
							.search-block-form.search-box input.form-search:-ms-input-placeholder {
								@include fontReset;
							}
							/* Firefox 18- */
							.search-block-form.search-box input.form-search:-moz-placeholder { 
								@include fontReset;
							}
						}
						.block.block-menu-menu-products-maincats-searchcap {
							position: relative;
							top: 12px;
							
							
							ul.menu-dropdown.menu.menu-style-processed {
								float: left;
								width: auto;
								justify-content: center;
								text-align: center;
								padding: 0;
								
								
								li.search-cap-opener {
									width: auto;
									float: left;
									margin: 1px 0 0 0;
									color: #555555; 
									
									
									a.nolink.has-submenu {
										width: 60px;
										font-size: 8px;
										font-family: 'Oxygen', sans-serif;
										border-color: #707070;
										border-width: 1px;
										border-style: solid;
										background-color: $steelGray;
										border-radius: 4px 0 0 4px;
										padding: 4px 3px;
										display: flex;
										align-items: center;
										justify-content: center;
										background-image: linear-gradient(to bottom, #ffffff59, #ffffff61 50%, #00000005 50%, #ffffff30);
										color: #555555;
										letter-spacing: normal;
										text-indent: -5px;
										
										span.sub-arrow {
											right: 2px;
										}
									}
									ul.sm-nowrap {
									
									}
								}
							}
						}
						
						.search-block-form.search-box {
							float: left;
							margin: 0;
							width:80%;
							top: 11px;
							height: 31px;
							
							.form-item.form-type-search.form-item-search-block-form {
								float: left;
								margin: 0;
								width: 80%;
								input.form-search {
					
				}
				
								input.form-search {
									animation: fadIn 1.2s 250ms forwards;
									border-color: $steelGrayDrk;
									border-width: 1px 0;
									position: relative;
									padding: 10px 20px;
									margin: 0;
									width: 100%;
									top: 11px;
									font-size: 12px;
									background-image: linear-gradient(to bottom, #FFF, #ffffff 50%, #f1f1f1 50%, #FFF);
								}
							}
							.form-actions {
								animation: fadIn .9s 200ms forwards;
								
								input.form-submit {
									top: 11px;
									border: 1px $steelGrayDrk solid;
									position: relative;
									border-radius: 0 4px 4px 0;
									padding: 2px;
									line-height: 1;
									font-size: 12px;
									text-indent: -999px;
									width: 40px;
									margin: 0;
									background: {
										color: $rust;
										image: linear-gradient( to bottom, #ffffff0d, #ffffff45 50%, #00000005 50%, #ffffff1f ), url(/files/images/search-glass-blustl.png);
										repeat: no-repeat;
										position: center;
										size: 100%, 60%;
									}
								}
							}
							.form-item.form-type-search.form-item-search-block-form {
								float: left;
								margin: 0;
								width:80%;
							}
						}
					}
				}
				/* ==## media 768 iPad1 ##== */
				.header-right-mid.header-right {
					.block.block-block-mad-in-america-desktop {
						width: 225px;
						margin-left: -36px;
						margin-top: 2px;

						.made-american {
							line-height: 1;
							position: relative;
							font: {
								size: 22px;
								family: 'Roboto', sans-serif;
								weight: 400;
							}
							text-align: center;
							text-transform: uppercase;
							color: #FF0000;
							margin-left: -14px;
							top: 8px;
							text-shadow: 1px 1px 1px #2b2d2e;
							
							.usa-color-two {
								color: #F0F3F4;
							}
							.usa-color-three {
								color: #1cabd9;
							}
						}
						h3.call-toll-free-mb  {
							display: block;
							color: $ice;
							font: {
								family: 'Open Sans', sans-serif;
								weight: 600;
								style: italic;
								size: 15px;
							}
							margin: 0;
							line-height: 1;	
							text-align: center;
							transform: translateY(10px);
							padding-top: 0;
							
							
							em {
								display: block;
								margin-left: -12px;
							}
						}
					}
				}
			}
			/* ==## media 768 iPad1 ##== */
			.header-bottom-row {
				height: auto;
				background-color: $steelBlue;
				
				.l-flexible-row {
					align-items: center;
				}
				.header-left-bottom.header-left {
					.header-identity-wrapper {
						width: auto;
						float: left;
						
						.header-site-name-wrapper { 
							width: auto;
							
							a.header-site-name-link {
								display: block;
								width: auto;
								color: $drab;
								padding: 0;
								
								.header-logo-wrapper {
									position: static;
									min-width: 180px;
									max-width: 100%;
									width: 185px;
									height: auto;
									margin: -12px 0;
									float: none;
									z-index: 222;
									
									img.header-logo {
										position: static;
										z-index: 10;
										top: -23px;
										height: auto;
										width: 100%;
										margin: inherit;
									}
								}
							}
						}
					}
				}
					
				.header-middle-bottom.header-mid {	
					padding: 0 0 0 40px;
					.block.block-system-main-menu.main-menu {
						ul.menu-dropdown {
							li {
								font-size: 14px;
								
								a {	
									display: flex;
									align-items: center;
									font: {
										size: 15px;
										weight: 400;
									}
									color:$ice; 
									padding: 5px 12px 4px;
									text-shadow: 1px 1px 2px #00000054;  
									line-height: 1.3;
									letter-spacing: 1.5px;
									
									span.sub-arrow {
										right:-1px;
									}
								}
							}
							li.has-children {
								a.has-submenu {
									margin-right: 8px;
									
									span.sub-arrow {
										transition: .2s ease;
										transform-origin: top;
									}
								}
								ul {
									li.has-children {
										a.has-submenu {						
											span.sub-arrow {
												transition: .2s ease;
												transform-origin: center;
											}
										}
										a.has-submenu.highlighted {
											span.sub-arrow {
												transform: rotateY(180deg);
												transform-origin: center;
												top: 50%;
												right:7px;
												transition: .8s ease;
											}
										}
									}
								}
								a.has-submenu.highlighted {
									span.sub-arrow {
										transform: rotateX(180deg);
										top: 58%;
										transition: .9s ease;
									}
								}
							}
						}
					}
				}
				.header-right-bottom.header-right {
	/* ### BEGIN iPad1 HeaderNav 768 products megamenu toggle ### */
					.block-block-product-category-mega-menu-toggle-wrapper {
						display: block;
					}
				}
			}
		}
	}
	/* ==## media 768 iPad1  - HERO SECTION - ##== */
	.layout .flexible-row {
		.no-container.hero-block {
			.row {
				.l-col.col-md-12.l-region--hero-block {
					display: grid;
					margin-bottom: 16px;
					padding: 0px;
					grid: {
						template-columns: 4fr 7fr 1fr;
						column-gap: 6px;
					}
					justify: {
						items: center;
						content: center;
					}
					align-items: center;					

					.block.topText-wrapper {
						grid-column-start: 2;
						padding: 0;
					}
					
					.block.block-easy-breadcrumb-easy-breadcrumb {
						margin: {
							left: 0;
							right: auto;
						}
						padding: 0;
						grid: {
							row: 1 /span 2;
							column: 1 / auto;
						}
						background-color: transparent;
												
						.container .breadcrumb {
							margin: 5px 0px 12px 20px;
							padding: 0;
						}
					}
					
					.block.mega-menu {
						padding: {
							left: 0;
							right: 0;
						}
						position: fixed;
						top: -100%;
						left: 0;
						right: 0;
						z-index: 1234;
						opacity: 0;
						background: white;
						transition: 300ms ease-in-out;
						
						li .view-content {
							background: none;
							padding-left: 0;
							padding-right: 0; 
							margin: auto;
							max-width: 18.5vw;
						}
	/* ==## media 768 iPad1 ##== */
						.view-header {
							padding: 20px 10px;
							border-bottom: 4px solid rgba(0,0,0,0.12);
							
							.menu-mega-top {
								position: relative;
								
								.row {
									align-items: center;
								
									ul {
										display: flex;
										flex-wrap: nowrap;
										padding-left: 0;
										margin: 0;
										margin-left: -54px;
										flex-direction: row;
										justify-content: space-between;
										
										li {
											float: left;
											list-style: none;
											
											a {
												color: #F0F3F4;
												text-decoration: none;
												font: {
													size: 13px;
													weight: normal;
													family: "roboto";
												}
												letter-spacing: normal;
											}
											a.close {
												display: block;
												width: 40px;
												height: 40px;
												background: {
													image: url(../images/icon/close.png);
													size: contain;
													repeat: no-repeat;
												}
												text-indent: -999999px;
												cursor: pointer;
												position: absolute;
												top: 0;
												right: -40px;
												display: none;
											}
										}
										li.lbtn {
											margin-right: 15px;
											margin-bottom: 0;
											text-align: center;
											position: relative;
											transition: .4s ease;
											
											a {
												display: block;
												background-color: #006585;
												padding: 3px 37px 2px 32px;
												border: {
													radius: 25px;
													color: #006585;
													style: solid;
													width: 2px;
												}
												min-width: 175px;
												position: relative;
												transition: .4s ease;
											}
											span {
												padding: 0px;
												position: absolute;
												top: 50%;
												right: 21px;
												width: 8px;
												height: 8px;
												transform: translateY(-50%) rotate(45deg);
												border: solid #FFF;
												border-width: 2px 2px 0 0;
												transition: .4s ease;
											}
										}
										li.lbtn:hover, li.lbtn:active, li.lbtn:focus  {
											transition: .2s ease;
											
											a {
												background-color: #87bcbf6b;
												color: $onyx;
												border: {
													color: $drab;
												}
												transition: .2s ease;

											}
											span {
												
												right: 15px;
												width: 9px;
												height: 9px;
												transform: translateY(-50%) rotate(45deg);
												border: solid $drab;
												border-width: 2px 2px 0 0;
												transition: .2s ease;
											}
										}
									}
									.col-md-4 {
										
										.back {
											position: absolute;
											top: calc(50%);
											left: 0;
											padding:28px;
											transform: translateY(-50%);
											width: auto;
											height: 100%;
											background: url(../images/arrow-l.png) center no-repeat;
											background-size: initial;
											text-indent: -9999px;
											overflow: hidden;
											cursor: pointer;
										}
										h3 {
											padding-left: 20%;
											font: {
												size: 28px;
												family: $headingFont;
												weight: bold;
											}
											color: #333333;
											margin: 0;
											max-width: 175px;
										}
									}
								}
							}
						}
						/* ==## media 768 iPad1 ##== */
						.view-content {
							.item-list ul {
								display: flex;
								flex-wrap: wrap;
								padding:0 5px;
								row-gap: 4px;
								
								li {
									list-style: none;
									margin-bottom: 0;
									height: auto;
									display: block;
									width: 100%;

									a {
										text-decoration: none;
										font: {
											size: 12px;
											family: "roboto";
											weight: 400;
										}
										letter-spacing: 0.4px;
										line-height: 1.3;
										padding: {
											top: 9px;
											bottom: 7px;
											left: 6px;
											right: 5px;
										}
										margin: {
											left: auto;
											right: auto;
										}
										display: block;
										border: {
											style: solid;
											color: #f0f3f4 #e9f3f3 #ececec #f0f3f4;
											width: 5px;
										}
										color: $steelBluegreen;
										box-shadow: inset 2px 2px 22px 2px #ffffff47;
										transition: 1.2s ease-out;
									}
									a:hover {
										color: $linkBlue;
										background-color: $ice;
										text-shadow: #fff 1px 1px 1px;
										transition: 280ms ease;
										border-color: #fff #e6f1f1 #e6f1f1 #fff;
										box-shadow: inset 2px 2px 28px 1px #fff, -1px 0px 7px -2px #afcccd8c;
									}
								}
								li.last {
								}
								li.first {
								 /*	border-top: 1px solid #00000021; */
								}
								li.parent {
									padding: 0;
									display: block;
									
									.views-field-name {
										padding: 5px 0px 0px 15px;
										margin-bottom: 5px;
										height: auto;
										
										a {
											color: #006585;
											font: {
												family: 'Chivo';
												size: 17px;
												weight: bold;
											}
											text: {
												decoration: none;
												transform: uppercase;
												align: left;
												shadow: -1px -1px 2px #fff, 1px 1px 2px #00000059;
											}
											padding: 12px 20px;
										}
										a:hover, a:active, a:focus {
											color: $onyx;
											background-color: $ice;
											border-bottom-color: #7AB4DB;
											border-right-color: #7AB4DB;
											transition: 350ms ease, border-color 100ms linear 0s ;
										}
									}
								}
								li.parent.first {
									border-top:none;
								}
								li.parent:nth-child(6),
								li.parent:nth-child(12),
								li.parent:nth-child(18),
								li.parent:nth-child(24) {
									clear: both;
								}
							}
						}
						/* ==## media 768 iPad1 ##== */

						>.block-content > .view-product-category-mega-menu {
							box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 36%);
							-webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 36%);
							
							.view-content {
								overflow: hidden;
							}
							>.view-content > .item-list {
								position: relative;
								
								> ul {
									display: grid;
									grid-template-columns: repeat(5, 1fr);
									padding: 0 5px;
									margin: 5px 5px 0;
								}
							}
						}
						.view-product-category-mega-menu > .view-header + .view-content {
							position: relative;
							height: calc(100vh - 103px);
							overflow-y: auto;
							background-color: #F0F3F4 ;
						}
					}
					/* ==## media 768 iPad1 ##== */
					.block.mega-menu.open {
						opacity: 1;
						visibility: visible;
						top: 0;
					}
					.mega-menu-toggle-wrapper {
						position: relative;
						padding: 4px;
						grid-column-start: 3;
						align-self: center;
						
						.block-content {
							a.hamburger {
								display: grid;
								grid: {
									template: {
										rows: auto 6px 7px 7px auto;
										columns: 30% auto;
										areas: 	'. .' 
												'bar1 txt' 
												'bar2 txt' 
												'bar3 txt' 
												'. .';
									}
									column-gap: 10px;
								}
								font: {
									size: 16px;
									family: $linkFont;
								}
								background: {
									position: top right;
									repeat: no-repeat;
									/* image: url(../images/icon/menubutton.png); */
									size: 35px 24px;
									color: $drab;
								}
								padding: {
									top: 3px;
									bottom: 2px;
									right: 40px;
									left:20px;
								}
								color: $ice;
								cursor: pointer;
								float: right;
								border: {
									radius: 100px;
									color: $ice;
									width: 2px;
									style: solid;
								}
								align-items: center;
								transition: background .6s ease;
								
								.txt {
									color: $sage;
									grid-area: txt;
									line-height: 1;
									align-self: auto;
								}
								.bar {
									background-color: $sage;
									height: 3px;
									border-radius: 100px;
								}
								.bar1 {
									grid-area: bar1;
									margin-top: 1px;
								}
								.bar2 {
									grid-area: bar2;
								}
								.bar3 {
									grid-area: bar3;
									margin-bottom: 2px;
								}
							}
							a.hamburger:active, a.hamburger:hover {
								background-color: #84b8bb85;
								border: {
									color: $drab;
								}
								transition: background .2s ease;
								
								.bar {
									background-color: $drab;
								}
								.txt {
									color: $onyx;
								}
							}
						}
					}
				}
			}
		}
	}
	.block.block-mini-layouts-header-blustl .no-container.region_12.top-nav-region .l-flexible-row.row {
		height: 0;
	}
	.topText {
		margin-top: 0;
	}
	.logged-in .block.mega-menu {
		margin-top : 34px;
	}
	/*** ####### product pages - ONLY - ### Header styles ### min 768 ### ***/
	body.node-type-product-display .layout .flexible-row {
		.no-container.hero-block .row {
			.l-col.col-md-12.l-region--hero-block {
				.mega-menu-toggle-wrapper {
					grid-row-start: auto;
				}
				.block.block-easy-breadcrumb-easy-breadcrumb {
				    margin: {
						left: 0;
						right: auto;
					}
					grid: {
						column: 1 / span 2;
						row: 1 / span 2;
					}
				}
			}
		}
	}

	/* ==## (0)(0) min 768 -AND- max 889 ##== */
	@media (max-width: 889px) {
		body .layout .layout-flexible-content header {
			header.l-header {
				.middle-header-row.no-container {
					.l-col.col-md-3.l-region--logo-spacer.header-left-mid.header-left {
						flex: 1 1 30%;
						max-width: 30%;
					}
					.l-col.col-md-6.l-region--top-navigation.header-middle-mid.header-mid {
					    flex: 1 1 40%;
						max-width: 48%;
					}
				}
			}
		}
	}/* ==## END ##-- min 768 -AND- max 889 --## END##== */
} /* (0)(0)==## END media 768 iPad1 END ##== */

/* ==#*********** +++ media 1024 iPad2  +++ ################== */
@media (min-width: 1024px) {
	body .layout .layout-flexible-content header header.l-header { 
		display:block;
		
		/* ==## media 1024 iPad2 ##== */
		.header-top-bar {
			.block.block-block-covid-message.covid-message {
				font: {
					weight: lighter;
					size: 13px;
				}
				color: $drab;
				line-height: 25px;
				margin-left:0;

				strong {
					font-size: 13px;
					font-weight: bold;
					font-family: "Open Sans";
					color: #444444;
				}
				a {
					font-weight: normal;
					color: $drab;
					text-decoration: underline;
					margin-left: 20px;
				}
			}
			.social-icon-block {
				float: right;
				
				.block-content ul {
					padding: 0;
					position: unset;
					display: flex;
					justify-content: center;
					/* padding: unset; */
					top: unset;
					left: unset;
					width: unset;
					height: unset;
					z-index: unset;
					background: transparent;
					
					li {
						a {
							font: {
								size: 11px;
								weight: normal;
								family: "Open Sans";
							}
							letter-spacing: normal;
							padding: 0 0 0 20px;
							margin-right: 20px;
							background: {
								position: top left;
								repeat: no-repeat;
								size: contain;
							}
						}
					}
				}
			}
			.header-left {
				padding-left: 30px;
			}
		}
		/* ==## media 1024 iPad2 ##== */
		.middle-header-row.no-container {
			height: 60px;
			
			.header-left-mid.header-left {
				.block-block-header-left {
					span.quality-tag {
						left: 0; 
						min-width: 250px;
						text-align: center;
						padding-left: 0px;
						width: 308px;
						display: block;
						position: relative;
						top: 1px;
						
						strong {
							font: {
								size: 15px;
							}
						}
					}
				}
			}
			/* ==## media 1024 iPad2 ##== */			
			.header-middle-mid.header-mid {
				.header-search-wrapper {
					margin-left: 73px;
					top: -1px;
					
					input.form-submit, input.form-search, li.search-cap-opener, a.nolink.has-submenu {
						height: 31px;
					}
					.search-block-form.search-box {
							
						/* Chrome/Opera/Safari */
						input.form-search::-webkit-input-placeholder { 
							@include fontReset;
						}
						/* Firefox 19+ */
						input.form-search::-moz-placeholder { 
							@include fontReset;
						} 
						/* IE 10+ */
						.search-block-form.search-box input.form-search:-ms-input-placeholder {
							@include fontReset;
						}
						/* Firefox 18- */
						.search-block-form.search-box input.form-search:-moz-placeholder { 
							@include fontReset;
						}
					}
					.block.block-menu-menu-products-maincats-searchcap {
						position: relative;
						top: 10px;
						
						ul.menu-dropdown.menu.menu-style-processed {
							
							li.search-cap-opener {
								width: 35px;
								float: left;
								margin: 1px 0 0 0;
								color: #555555; 
								
								a.nolink.has-submenu {
									height: 31px;
									
									span.sub-arrow {
										
									}
								}
							}
						}
					}
					.search-block-form.search-box {
						float: left;
						margin: 0;
						width:80%;
						top: 11px;
						height: 31px;
						
						.form-item.form-type-search.form-item-search-block-form {
							float: left;
							margin: 0;
							width: 80%;
							
							input.form-search {
								border-color: $steelGrayDrk;
								border-width: 1px 0;
								position: relative;
								padding: 10px 5px 10px 40px;
								margin: 0;
								width: 100%;
								top: 11px;
								font-size: 12px;
								background-image: linear-gradient(to bottom, #FFF, #ffffff 50%, #f1f1f1 50%, #FFF);
							}
						}
						input.form-submit {
							top: 11px;
							border: 1px $steelGrayDrk solid;
							position: relative;
							border-radius: 0 4px 4px 0;
							padding: 2px;
							line-height: 1;
							font-size: 12px;
							text-indent: -999px;
							width: 40px;
							margin: 0;
							background: {
								color: $rust;
								image: linear-gradient( to bottom, #ffffff0d, #ffffff45 50%, #00000005 50%, #ffffff1f ), url(/files/images/search-glass-blustl.png);
								repeat: no-repeat;
								position: center;
								size: 100%, 60%;
							}
						}
						.form-item.form-type-search.form-item-search-block-form {
							float: left;
							margin: 0;
							width:80%;
						}
					}
				}
			}
			/* ==## media 1024 iPad2 ##== */
			.header-right-mid.header-right {
				.block.block-block-mad-in-america-desktop {
					width: 225px;
					margin-left: -36px;
					margin-top: 2px;

					.made-american {
						line-height: 1;
						position: relative;
						font: {
							size: 22px;
						}
						text-transform: uppercase;
						margin-left: 0;
						top: 7px;
						width:225px;
						text-align:center;
					}
					h3.call-toll-free-mb  {
						display: block;
						color: $ice;
						font: {
							family: 'Open Sans', sans-serif;
							weight: 600;
							style: italic;
							size: 15px;
						}
						margin: 0;
						line-height: 1;	
						text-align: center;
						transform: translateY(10px);
						padding-top: 0;
						width:225px;
						
						
						em {
							display: block;
							margin-left: 0;
						}
					}
				}
			}
		}
		/* ==## 1024 iPad2 ##== */
		.header-bottom-row {
			height: auto;
			
			.l-flexible-row {
				align-items: center;
			}
			.header-left-bottom.header-left {
				.header-identity-wrapper {
					width: auto;
					float: left;
					
					.header-site-name-wrapper { 
						width: auto;
						
						a.header-site-name-link {
							display: block;
							width: auto;
							color: $drab;
							padding: 0;
							
							.header-logo-wrapper {
								min-width: 250px;
								width: 280px;
								
								img.header-logo {
									position: static;
									z-index: 10;
									top: -23px;
									height: auto;
									width: 100%;
									margin: inherit;
								}
							}
						}
					}
				}
			}
			
			.header-middle-bottom.header-mid {	
				padding: 0 0 0 64px;

				.block.block-system-main-menu.main-menu {
					ul li {
						font-size: 14px;
						
						a {	
							display: flex;
							align-items: center;
							font: {
								size: 16px;
								weight: 400;
							}
							color:$ice; 
							padding: 7px 10px;
							text-shadow: 1px 1px 2px #00000054;  
							line-height: 1.3;
							letter-spacing: 1px;
						}
					}
				}
			}
		/*### headerNav 1024 megamenu toggle ###*/
			.header-right-bottom.header-right {
				.block-block-product-category-mega-menu-toggle-wrapper {
					display: block;
					
					.block-content {
						a.hamburger {
							display: grid;
							grid: {
								template: {
									rows: auto 6px 7px 7px 1fr;
									columns: 30% auto;
									areas: 	'. .' 
											'bar1 txt' 
											'bar2 txt' 
											'bar3 txt' 
											'. .';
								}
								column-gap: 10px;
							}
							font: {
								size: 16px;
								family: $linkFont;
							}
							background: {
								position: top right;
								repeat: no-repeat;
								size: 35px 24px;
								color: $steelBlue;
							}
							padding: {
								top: 3px;
								bottom: 2px;
								right: 40px;
								left:20px;
							}
							margin-left: auto;
							margin-right: 6%;
							color: $ice;
							cursor: pointer;
							float: right;
							border: {
								radius: 0;
								color: $steelBlue;
								width: 2px;
								style: solid;
							}
							align-items: center;
							transition: background .6s ease;
							
							.txt {
								color: $sage;
								grid-area: txt;
								line-height: 1;
								align-self: auto;
							}
							.bar {
								background-color: $sage;
								height: 3px;
								border-radius: 100px;
							}
							.bar1 {
								grid-area: bar1;
								margin-top: 1px;
							}
							.bar2 {
								grid-area: bar2;
							}
							.bar3 {
								grid-area: bar3;
								margin-bottom: 2px;
							}
						}
						a.hamburger:active, a.hamburger:hover {
							background-color: $sage;
							border: {
								color: $steelBlue;
							}
							text-decoration: none;
							transition: background .2s ease;
							
							.bar {
								background-color: $onyx;
							}
							.txt {
								color: $onyx;
							}
						}	 		
					}
				}
			}
		}
	}
     /* ==## media 1024 iPad2 ##== */
 
	.layout .flexible-row .no-container.hero-block {
		.row .l-col.col-md-12.l-region--hero-block {
			grid-template-columns: 1fr 2fr 1fr;
		
			.block.block-easy-breadcrumb-easy-breadcrumb {
				grid-column-start: 3;
				
				.container .breadcrumb {
					background: transparent;
					display: flex;
					flex-wrap: wrap;
					margin: 0px 20px 2px 20px;
					padding: 0;
					position: relative;
					z-index: 2;
					justify-content: flex-end;
					
					.item:last-child {
						display: flex;
						width: 100%;
						pointer-events: none;
						text-align: right;
						right: 0;
						margin: auto;
						justify-content: flex-end;
						flex: {
							wrap: wrap;
							direction: row;
						}
						align-items: baseline;
					}
				}
			}
			.mega-menu-toggle-wrapper {
				display: none;
				
				.block-content a.hamburger {
					display: none;
					
					.txt {
						display: none;
					}
				}
			}
		}
	}
	
	/*** ####### product pages - ONLY - ### Header styles ### min 1024 ### ***/
	body.node-type-product-display .layout .flexible-row {
		.no-container.hero-block .row {
			.l-col.col-md-12.l-region--hero-block {
				.block.block-easy-breadcrumb-easy-breadcrumb {
				    margin: {
						left: auto;
						right: 0;
					}
					grid: {
						column: 2 / span 2;
						row: 2 / span 2;
					}
				}
			}
		}
	}


}/* (0)(0)==## END media 1024 iPad2 END ##== */

/* -==++++++-=## media 1155 desktop ##=-+++++++==- */
@media (min-width: 1155px){
	
	/*### headerNav 1155px megamenu toggle ###*/
	body .layout .layout-flexible-content header header.l-header { 
		.header-bottom-row {
			.header-right-bottom.header-right {
				.block-block-product-category-mega-menu-toggle-wrapper {
					.block-content {
						a.hamburger {
							margin-left: auto;
							margin-right: auto;
							float: left;
						}
					}
				}
			}
		}
	}
	
}/* (0)(0)==## END media 1155 desktop END ##== */

@media (min-width: 1200px) { 
	header header.l-header {
		.middle-header-row.no-container {
			.header-right-mid.header-right {
				.block.block-block-mad-in-america-desktop {
					.made-american {
					
					}
					h3.call-toll-free-mb  {
						
					}
				}
			}
		}
		.header-bottom-row {
			.header-right-bottom.header-right {
				
			}
		}
		.l-region--header-middle-bottom.header-middle-bottom.header-mid {
			
			
		}
	}
}
/* ==## media 1600 Desktop Wide ##== */
@media (min-width: 1600px) {
	header header.l-header {
		.header-top-bar {
			
		}
		.middle-header-row.no-container {
			.block-block-header-left span.quality-tag {
				strong {
					font-size: 16px;
				}
			}
		}
		.header-bottom-row {
			height: auto;
			
			.l-flexible-row {
				align-items: center;
				
				.header-site-name-link {
					display: block;
					width: auto;
				}
			}
		}
	}
	.hero-block {
		.block-views-term-ref-categories-block-6 {
			.view.view-term-ref-categories.view-id-term_ref_categories.view-display-id-block_6 {
				.views-view-grid {
					min-width: auto ;
					width: 100% ;
					margin: auto;
				}
			}
		}
	}
}
 /* (0)(0)==## END media 1600 Desktop-Wide END ##== */


/* ++==## == BEGIN - Tiny Screens ONLY ++ Max 280 ++ ##==++ */
@media (max-width: 280px) {
/* only mobile */
	header.l-header {
		.top-nav-region {
			.l-region--top-nav {
				.block-system-header {
					height: auto;
					padding: 2px 5px;
					
					.block-content {
						.header-identity-wrapper {
							.header-site-name-wrapper {
								a.header-site-name-link {
									height: auto;
									padding: 2px 0 0 0;
									
									.header-logo-wrapper {
										height: auto;
										
										img.header-logo {
											height: auto;
											max-height: 100%;
										}
									}
								}
							}	
						}										
					}
				}
				.block.block-menu-menu-mobile-primary-nav.mobile-nav.primary-nav.mobile-display {
					margin-left: 2vw;
				}
				.block.block-search-form.search-mobile.search-glass.mobile-display {
					margin-right: 2vw;
						
				}
			}
		}
	}	
	div.flexible-row {
		.region_12.hero-block {
			padding: {
				left: 0;
				right: 0;
			}
				
			.block.block-easy-breadcrumb-easy-breadcrumb {
				.container nav.breadcrumb {
					margin: {
						left: 10px;
						right: 10px;
					}
					
					.item:last-child {
						padding-left: 3px;
					}
				}
			}
		}
	}
} /* ==## END ## --- Max 280 --- tiny screens ONLY - - ## END ##== */



